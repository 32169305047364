import React from "react"
import SectionHeading from "../SectionHeading"
import { css } from "@emotion/core"
// import { routes } from "src/constants"
import Card from "./WideCard"

const wrapper = css`
  @media (max-width: 1199px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-top: 14px;
  }
`

const cards = css`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1199px) {
    justify-content: flex-start;
  }
  @media (max-width: 800px) {
    flex-direction: column;
  }
`

export default function Unfiltered({ data }) {
  return (
    <div css={wrapper}>
      <SectionHeading
        title="Unfiltered"
        // link={routes.unfiltered}
      />
      <div css={cards}>
        {data.unfiltered.nodes.map((d) => {
          return <Card data={d} key={d.id} />
        })}
      </div>
    </div>
  )
}

import { css } from "@emotion/core"
import Img from "gatsby-image"
import { Link } from "gatsby"
import React from "react"
import { If } from "src/components"
import SectionHeading from "./SectionHeading"
import { routes } from "src/constants"
import * as util from "src/util"
import { colors } from "css/theme"

const wrapper = css`
  margin-left: 0.7rem;
  border-top: 1px solid ${colors.skyblue};
  padding-top: 10px;
  @media (max-width: 1199px) {
    margin-top: 1.5rem;
    margin-left: 0;
  }
`

const postWrapper = css`
  margin-bottom: 0;
`

const postElement = css`
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
  width: 100%;
  max-width: 372px;
  height: 390px;
  position: relative;
  display: block;
  background-image: linear-gradient(
    118deg,
    #986455 0%,
    #1d3f46 33%,
    #0c2932 97%
  );
  padding: 36px 42px 28px;
  @media (max-width: 650px) {
    padding: 2.5rem 1.5rem;
  }
`

const content = css`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const icon = css`
  width: 48px;
  height: 48px;
  margin-left: auto;
  margin-right: -0.5rem;
  margin-top: -0.5rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const title = css`
  font-family: var(--heading);
  color: white;
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 1.35;
  margin-bottom: 10px;
  margin-top: auto;
  @media (max-width: 650px) {
    font-size: 1.3rem;
  }
`

const subtitle = css`
  color: white;
  font-size: 17px;
  line-height: 1.56;
  margin-bottom: 0.5rem;
  flex-shrink: 1;
  min-height: 0;
  @media (max-width: 650px) {
    font-size: 1rem;
  }
`

export default function FromTheLab({ data }) {
  const link = util.formatSlug(data, routes.from_the_lab)
  const [iconImg, iconAltText] = util.computeAcfImageAttr(data.acf.icon)

  return (
    <div css={wrapper}>
      <SectionHeading
        title="From the Lab"
        // link={routes.from_the_lab}
      />
      <div css={postWrapper}>
        <Link to={link} css={postElement}>
          <div css={content}>
            <If test={iconImg}>
              <div css={icon}>
                <Img fluid={iconImg} alt={iconAltText} />
              </div>
            </If>
            <h2 css={title} className="clamp-4">
              {data.title}
            </h2>
            <div css={subtitle} className="clamp-4">
              {data.acf.subtitle}
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import * as util from "src/util"

const wrapper = css`
  color: white;
  padding-top: 1rem;
  padding-bottom: 94px;
  @media (max-width: 1199px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  @media (max-width: 650px) {
    padding-top: 2.5rem;
    padding-bottom: 3rem;
  }
`

const heading = css`
  font-family: var(--heading);
  text-align: center;
  line-height: 1.65;
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  text-align: left;
  @media (max-width: 650px) {
    font-size: 24px;
  }
`

const list = css`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 1199px) {
    max-width: 754px;
    flex-direction: column;
  }
`

const element = css`
  width: 363px;
  max-width: 100%;
  position: relative;
  padding: 1rem 32px 32px 0;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 1199px) {
    margin-bottom: 20px;
    width: 100%;
    padding-bottom: 0;
  }
  @media (max-width: 650px) {
    padding-right: 1rem;
  }
`

const content = css`
  position: relative;
  z-index: 2;
`

const title = css`
  color: white;
  font-size: 28px;
  font-family: var(--heading);
  font-weight: bold;
  line-height: 1.36;
  @media (max-width: 650px) {
    font-size: 20px;
  }
`

const description = css`
  font-size: 17px;
  margin-top: 1rem;
  margin-bottom: 0;
  color: #c5c5c5;
`

export default function Blog({ data }) {
  return (
    <div css={wrapper}>
      <h2 css={heading}>From the blog</h2>
      <div css={list}>
        {data.map((post) => {
          return (
            <Link to={util.computePostLink(post)} key={post.id} css={element}>
              <div css={content}>
                <div css={title} className="clamp-3">
                  {post.title}
                </div>
                <p css={description}>{post.yoast_meta.yoast_wpseo_metadesc}</p>
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

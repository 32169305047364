import React from "react"
import { Link } from "gatsby"

import Icon from "src/images/library/right-icon.svg"
import { css } from "@emotion/core"

const wrapper = css`
  color: #d0d0d0;
  font-size: 15px;
  line-height: 2;
  font-family: var(--heading);
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`

const linkWrapper = css`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  &:hover {
    h2 {
      color: white;
    }
    img {
      left: 5px;
    }
  }
`

const icon = css`
  margin-left: 8px;
  position: relative;
  transition: left 0.2s ease;
  left: 0;
`

export default function SectionHeading({ title, link }) {
  return link ? (
    <Link to={link} css={linkWrapper}>
      <h2 css={wrapper}>
        {title}{" "}
        <img src={Icon} width={24} height={24} css={icon} alt="View more" />
      </h2>
    </Link>
  ) : (
    <h2 css={wrapper}>
      {title}{" "}
      <img src={Icon} width={24} height={24} css={icon} alt="View more" />
    </h2>
  )
}

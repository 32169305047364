import React from "react"

export default function Slider({ data }) {
  // const posts = [
  //   ...data.redTeamFeatured.nodes,
  //   ...data.handbookFeatured.nodes,
  //   ...data.siegeCraftFeatured.nodes,
  // ]
  return <div></div>
}

import React from "react"
import SectionHeading from "./SectionHeading"
import { routes } from "src/constants"
import { css } from "@emotion/core"
import { colors } from "css/theme"
import { Link } from "gatsby"
import * as util from "src/util"

const wrapper = css`
  border-top: 1px solid ${colors.skyblue};
  padding-top: 10px;
  width: calc(100% - 18px - 363px);
  margin-right: 0.7rem;
  flex-shrink: 0;
  @media (max-width: 1199px) {
    width: 100%;
  }
`

const element = css`
  display: block;
  &:hover {
    text-decoration: none;
  }
`

const title = css`
  font-size: 34px;
  line-height: 1.2;
  color: white;
  font-family: var(--heading);
  font-weight: bold;
  margin-top: 30px;
  max-width: 610px;
  margin-bottom: 20px;

  @media (max-width: 650px) {
    font-size: 24px;
  }
`

const intro = css`
  font-size: 17px;
  line-height: 1.56;
  color: #c5c5c5;
  max-width: 610px;
  margin-bottom: 6px;
  @media (max-width: 650px) {
    font-size: 1rem;
  }
`

export default function SiegeCraft({ data }) {
  return (
    <div css={wrapper}>
      <SectionHeading
        title="SiegeCraft"
        // link={util.formatSlug(data, routes.siege_craft)}
      />
      <Link to={util.formatSlug(data, routes.siege_craft)} css={element}>
        <div css={title}>{data.title}</div>
        <p css={intro} className="clamp-3">
          {data.acf.intro}
        </p>
      </Link>
    </div>
  )
}

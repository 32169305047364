import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import Img from "gatsby-image"
import PlayIcon from "src/images/library/play.svg"
import * as util from "src/util"
import { routes } from "src/constants"
import { If } from "src/components"

const wrapper = css`
  width: calc(50% - 0.35rem);
  max-width: 100%;
  height: 350px;
  padding: 55px 37px 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
  background-color: gray;
  @media (max-width: 1199px) and (min-width: 801px) {
    &:first-of-type {
      margin-right: 0.7rem;
    }
  }
  @media (max-width: 800px) {
    width: 100%;
    &:first-of-type {
      margin-bottom: 18px;
    }
    padding: 1.5rem;
    padding-right: 3.5rem;
  }
  @media (max-width: 650px) {
    height: 320px;
  }
`

const content = css`
  position: relative;
  z-index: 2;
`

const playIcon = css`
  z-index: 2;
  margin-top: auto;
  margin-left: -8px;
`

const image = css`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

const title = css`
  font-family: var(--heading);
  color: white;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.46;
  max-width: 320px;
  @media (max-width: 650px) {
    font-size: 20px;
  }
`

const author = css`
  margin-top: 6px;
  color: white;
  font-size: 17px;
  line-height: 1.56;
  max-width: 220px;
  @media (max-width: 650px) {
    font-size: 1rem;
  }
`

export default function FromTheLabListingCard({ data }) {
  const [imageUrl, altText] = util.computeImageAttr(data)
  return (
    <Link css={wrapper} to={util.formatSlug(data, routes.unfiltered)}>
      <If test={imageUrl}>
        <div css={image}>
          <Img fluid={imageUrl} alt={altText} />
        </div>
      </If>

      <div css={content}>
        <div css={title}>{data.title}</div>
        <div css={author}>With {data.acf.guest_name}</div>
      </div>

      <div css={playIcon}>
        <img
          src={PlayIcon}
          alt="This content contains a video"
          width={48}
          height={48}
        />
      </div>
    </Link>
  )
}

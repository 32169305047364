import { css } from "@emotion/core"

import { Link } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { If } from "src/components"
import * as util from "src/util"
import SectionHeading from "./SectionHeading"
import { routes } from "src/constants"
import { colors } from "css/theme"

const wrapper = css`
  border-top: 1px solid ${colors.skyblue};
  padding-top: 10px;
`

const postWrapper = css`
  margin-bottom: 0;
`

const postElement = css`
  text-decoration: none;
  :hover {
    text-decoration: none;
  }
  width: 100%;
  max-width: 744px;
  height: 390px;
  position: relative;
  display: block;
  background: gray;
`

const imageWrapper = css`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
`

const content = css`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  padding: 28px 42px;
  @media (max-width: 650px) {
    padding: 1.5rem;
  }
`

const title = css`
  font-family: var(--heading);
  color: white;
  font-size: 2.25rem;
  font-weight: bold;
  line-height: 1.35;
  margin-bottom: 10px;
  @media (max-width: 650px) {
    font-size: 1.3rem;
  }
`

const subtitle = css`
  color: white;
  font-size: 17px;
  line-height: 1.56;
  margin-bottom: 0.5rem;
  @media (max-width: 650px) {
    font-size: 1rem;
  }
`

export default function RedTeamChronicles({ data }) {
  const [imageUrl, altText] = util.computeImageAttr(data)
  const link = util.formatSlug(data, routes.red_team_chronicles)
  return (
    <div css={wrapper}>
      <SectionHeading
        title="Red Team Chronicles"
        link={routes.red_team_chronicles}
      />
      <div css={postWrapper}>
        <Link to={link} css={postElement}>
          <div css={imageWrapper}>
            <If test={imageUrl}>
              <Img
                fluid={imageUrl}
                alt={altText}
                loading="eager"
                fadeIn={false}
              />
            </If>
          </div>
          <div css={content}>
            <h2 css={title} className="clamp-2">
              {data.title}
            </h2>
            <div css={subtitle} className="clamp-3">
              {data.acf.subtitle}
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

import React from "react"
import { graphql } from "gatsby"

import SEO from "../../components/seo"
import ogImage from "images/seo/library.png"
import "src/queries/featured_media"

import PageLayout from "src/components/PageLayout"
import { css } from "@emotion/core"
import { colors } from "css/theme"

import RedTeamChronicles from "library/homepage/RedTeamChronicles"
import FromTheLab from "library/homepage/FromTheLab"
import Unfiltered from "library/homepage/Unfiltered/index"
import SiegeCraft from "library/homepage/SiegeCraft"
import ShadowHunt from "library/homepage/ShadowHunt"
import Handbooks from "library/homepage/Handbooks"
import Blog from "library/homepage/Blog"
import Slider from "library/homepage/Slider"

const wrapper = css`
  background-image: linear-gradient(to bottom, #000000, #001f24 19%);
  margin-top: -160px;
  padding-top: 160px;
  min-height: 100vh;
  color: white;
`

const content = css`
  max-width: 1128px;
  margin-left: auto;
  margin-right: auto;
`

const topRow = css`
  display: flex;
  border-bottom: 1px solid ${colors.skyblue};
  padding-bottom: 1.5rem;
  margin-bottom: 10px;
  margin-top: 40px;
  @media (max-width: 1199px) {
    flex-direction: column;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
`
const middleRow = css`
  display: flex;
  margin-top: 1.5rem;
  padding-bottom: 2.5rem;
  @media (max-width: 1199px) {
    flex-direction: column;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
`

function Wrapper({ data }) {
  return (
    <div>
      <SEO
        title="The Library by Smokescreen"
        description="Field-tested incident response handbooks, the latest attack guides, and interesting ideas from the fringe of cybersecurity – The Library is home to practical resources created by our threat hunting and offensive security teams."
        image={ogImage}
      />
      <PageLayout options={{ headingBackground: false }}>
        <div css={wrapper}>
          <Slider data={data} />
          <div css={content}>
            <div css={topRow}>
              <RedTeamChronicles data={data.redTeam.nodes[0]} />
              <FromTheLab data={data.fromTheLab.nodes[0]} />
            </div>
            <div>
              <Unfiltered data={data} />
            </div>
            <div css={middleRow}>
              <SiegeCraft data={data.siegeCraft.nodes[0]} />
              <ShadowHunt data={data.shadowhunt.nodes[0]} />
            </div>
            <Handbooks data={data.handbooks.nodes} />
            <Blog data={data.blog.nodes} />
          </div>
        </div>
      </PageLayout>
    </div>
  )
}

export default Wrapper

export const pageQuery = graphql`
  query LearningHubQuery {
    redTeam: allWordpressWpRedTeamChronicles(
      filter: { acf: { featured_in_learning_hub: { eq: "yes" } } }
      limit: 1
    ) {
      nodes {
        id
        slug
        title
        wordpress_id
        ...FeaturedMediaRedTeam
        acf {
          subtitle
        }
      }
    }
    fromTheLab: allWordpressWpFromTheLab(
      filter: { acf: { featured_in_learning_hub: { eq: "yes" } } }
      limit: 1
    ) {
      nodes {
        id
        slug
        title
        wordpress_id
        acf {
          icon {
            alt_text
            large: localFile {
              childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          subtitle
        }
      }
    }
    unfiltered: allWordpressWpUnfiltered(
      filter: {
        acf: { is_primary: { eq: false }, highlight_as_quote: { eq: false } }
      }
      limit: 2
    ) {
      nodes {
        id
        slug
        title
        wordpress_id
        acf {
          guest_name
        }
        ...FeaturedMediaUnfiltered
      }
    }
    siegeCraft: allWordpressWpSiegeCraft(
      filter: { acf: { featured_in_learning_hub: { eq: "yes" } } }
      limit: 1
    ) {
      nodes {
        id
        title
        slug
        wordpress_id
        acf {
          subtitle
          intro
        }
      }
    }
    shadowhunt: allWordpressWpShadowhuntIntel(limit: 1) {
      nodes {
        id
        acf {
          quote
        }
        slug
        wordpress_id
        status
      }
    }
    handbooks: allWordpressWpHandbook(
      filter: { acf: { featured_in_learning_hub: { eq: "yes" } } }
      limit: 1
    ) {
      nodes {
        id
        title
        slug
        wordpress_id
        status
        yoast_meta {
          yoast_wpseo_metadesc
        }
        ...FeaturedMediaHandbook
      }
    }
    blog: allWordpressPost(
      filter: { acf: { featured_in_learning_hub: { eq: "yes" } } }
      limit: 3
    ) {
      nodes {
        id
        title
        slug
        wordpress_id
        status
        yoast_meta {
          yoast_wpseo_metadesc
        }
      }
    }
    redTeamFeatured: allWordpressWpRedTeamChronicles(
      filter: { acf: { learning_hub_carousel: { ne: "none" } } }
    ) {
      nodes {
        id
        acf {
          learning_hub_carousel_image {
            alt_text
            large: localFile {
              childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          learning_hub_carousel
        }
        slug
        status
        title
        wordpress_id
      }
    }
    handbookFeatured: allWordpressWpHandbook(
      filter: { acf: { learning_hub_carousel: { ne: "none" } } }
    ) {
      nodes {
        id
        acf {
          learning_hub_carousel_image {
            alt_text
            large: localFile {
              childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          learning_hub_carousel
        }
        slug
        status
        title
        wordpress_id
      }
    }
    siegeCraftFeatured: allWordpressWpSiegeCraft(
      filter: { acf: { learning_hub_carousel: { ne: "none" } } }
    ) {
      nodes {
        id
        acf {
          learning_hub_carousel_image {
            alt_text
            large: localFile {
              childImageSharp {
                fluid(maxWidth: 800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          learning_hub_carousel
        }
        slug
        status
        title
        wordpress_id
      }
    }
  }
`

// unfilteredQuote: allWordpressWpUnfiltered(
//   filter: { acf: { highlight_as_quote: { eq: true } } }
//   limit: 1
// ) {
//   nodes {
//     id
//     slug
//     title
//     wordpress_id
//     acf {
//       guest_name
//       quote
//       quote_image {
//         large: localFile {
//           childImageSharp {
//             fluid(maxWidth: 800, quality: 100) {
//               ...GatsbyImageSharpFluid_withWebp
//             }
//           }
//         }
//       }
//     }
//   }
// }
// unfilteredPrimary: allWordpressWpUnfiltered(
//   filter: { acf: { is_primary: { eq: true } } }
//   limit: 1
// ) {
//   nodes {
//     id
//     slug
//     title
//     acf {
//       guest_name
//       primary_video_image {
//         large: localFile {
//           childImageSharp {
//             fluid(maxWidth: 800, quality: 100) {
//               ...GatsbyImageSharpFluid_withWebp
//             }
//           }
//         }
//       }
//     }
//   }
// }

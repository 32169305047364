import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import * as util from "src/util"
import { routes } from "src/constants"
import { colors } from "css/theme"
import { btn } from "css/primitives"
import { If } from "src/components"
import Img from "gatsby-image"

const wrapper = css`
  border-top: 1px solid ${colors.brightBlue};
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${colors.brightBlue};
  position: relative;
  width: 100%;
  @media (max-width: 1199px) {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    width: calc(100% - 3rem);
  }
`

const content = css`
  position: relative;
  z-index: 1;
  color: white;
  max-width: 1128px;
  margin-left: auto;
  margin-right: auto;
`

const list = css`
  margin-bottom: 2.5rem;
  @media (max-width: 800px) {
    margin-bottom: 0;
  }
`

const element = css`
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: none;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const heading = css`
  font-family: var(--heading);
  font-size: 28px;
  font-weight: bold;
  line-height: 1.65;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  @media (max-width: 650px) {
    font-size: 24px;
    margin-bottom: 2rem;
  }
`

const imageWrapper = css`
  width: 335px;
  height: 232px;
  max-width: 100%;
  position: relative;
  margin-right: 94px;
  flex-shrink: 0;
  @media (max-width: 1023px) {
    margin-right: 32px;
  }
  @media (max-width: 800px) {
    margin-right: 0;
    margin-bottom: 32px;
  }
  @media (max-width: 359px) {
    height: 210px;
  }
`

const image = css`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  img {
    object-fit: contain !important;
  }
`

const contentWrapper = css`
  max-width: 630px;
`

const title = css`
  color: white;
  font-family: var(--heading);
  font-size: 28px;
  line-height: 1.36;
  margin-bottom: 20px;
  font-weight: bold;
  @media (max-width: 650px) {
    font-size: 22px;
  }
`

const button = [
  btn,
  css`
    width: 100%;
    max-width: 333px;
    background-color: ${colors.orange};
    color: white;
    display: block;
    transition: box-shadow 0.3s ease;
    box-shadow: none;
    &:hover {
      color: white;
      box-shadow: 5px 5px 8px 0 rgba(0, 0, 0, 0.42);
    }
    @media (max-width: 1199px) {
      margin-left: 0;
    }
    @media (max-width: 800px) {
      margin-bottom: 1.5rem;
    }
  `,
]

const description = css`
  font-size: 17px;
  color: #c5c5c5;
  line-height: 1.65;
  margin-bottom: 24px;
`

export default function Handbooks({ data }) {
  return (
    <div css={wrapper}>
      <div css={content}>
        <h2 css={heading}>Handbooks</h2>
        <div css={list}>
          {data.map((post) => {
            const [imageUrl, altText] = util.computeImageAttr(post)
            return (
              <Link
                to={util.formatSlug(post, routes.handbooks)}
                key={post.id}
                css={element}
              >
                <div css={imageWrapper}>
                  <If test={imageUrl}>
                    <div css={image}>
                      <Img fluid={imageUrl} alt={altText} />
                    </div>
                  </If>
                </div>
                <div css={contentWrapper}>
                  <div css={title}>{post.title}</div>
                  <div css={description}>
                    {post.yoast_meta.yoast_wpseo_metadesc}
                  </div>
                  <div css={button}>Get the Handbook</div>
                </div>
              </Link>
            )
          })}
        </div>
        <div></div>
      </div>
    </div>
  )
}

import React from "react"
import { Link } from "gatsby"
import SectionHeading from "./SectionHeading"
import { routes } from "src/constants"
import { css } from "@emotion/core"
import { colors } from "css/theme"
import { btn } from "css/primitives"
import * as util from "src/util"

const wrapper = css`
  border-top: 1px solid ${colors.skyblue};
  padding-top: 10px;
  flex-grow: 1;
  @media (max-width: 1199px) {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
  }
`

const element = css`
  margin-top: 30px;
  @media (max-width: 1199px) {
    margin-top: 10px;
  }
`

const quote = css`
  font-family: var(--heading);
  font-size: 34px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1.2;
  color: ${colors.orange};
  margin-bottom: 24px;
  max-width: 280px;
  @media (max-width: 650px) {
    font-size: 24px;
  }
`

const button = [
  btn,
  css`
    width: 100%;
    max-width: 333px;
    background-color: ${colors.orange};
    color: white;
    display: block;
    transition: box-shadow 0.3s ease;
    box-shadow: none;
    &:hover {
      color: white;
      box-shadow: 5px 5px 8px 0 rgba(0, 0, 0, 0.42);
    }
  `,
]

export default function ShadowHunt({ data }) {
  return (
    <div css={wrapper}>
      <SectionHeading
        title="ShadowHunt Intel"
        // link={routes.shadowhunt_intel}
      />
      <div css={element}>
        <div css={quote} className="clamp-4">
          {data.acf.quote}
        </div>
        <Link to={util.formatSlug(data, routes.shadowhunt_intel)} css={button}>
          Download the Report
        </Link>
      </div>
    </div>
  )
}
